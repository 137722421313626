@charset "UTF-8";

// Fonts
$font_title: Poppins;

*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

:before,
:after {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: $font_title;
}

body {
  margin: 0;
  line-height: inherit;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
  print-color-adjust: unset;
}

[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover,
[type="checkbox"]:checked:focus,
[type="radio"]:checked:hover,
[type="radio"]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="checkbox"]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type="checkbox"]:indeterminate:hover,
[type="checkbox"]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type="file"]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  text-decoration: underline;
  font-weight: 500;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  padding-left: 1.625em;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  padding-left: 1.625em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-left-width: 0.25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "\201c""\201d""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-weight: 600;
  font-size: 0.875em;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before {
  content: "`";
}

.prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding: 0.8571429em 1.1428571em;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before {
  content: none;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding: 0.5714286em;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}

.prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.right-10 {
  right: 2.5rem;
}

.left-0 {
  left: 0px;
}

.top-8 {
  top: 2rem;
}

.left-1\/2 {
  left: 50%;
}

.right-1\/2 {
  right: 50%;
}

.top-full {
  top: 100%;
}

.right-full {
  right: 100%;
}

.bottom-0 {
  bottom: 0px;
}

.top-2 {
  top: 0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.z-\[1\] {
  z-index: 1;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-mt-72 {
  margin-top: -18rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.-mr-3 {
  margin-right: -0.75rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!hidden {
  display: none !important;
}

.h-full {
  height: 100%;
}

.h-12 {
  height: 3rem;
}

.h-10 {
  height: 2.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-14 {
  height: 3.5rem;
}

.h-8 {
  height: 2rem;
}

.h-16 {
  height: 4rem;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-\[55rem\] {
  max-height: 55rem;
}

.max-h-full {
  max-height: 100%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.w-6 {
  width: 1.5rem;
}

.w-1\/2 {
  width: 50%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-auto {
  width: auto;
}

.w-5 {
  width: 1.25rem;
}

.w-screen {
  width: 100vw;
}

.w-16 {
  width: 4rem;
}

.w-4 {
  width: 1rem;
}

.min-w-0 {
  min-width: 0px;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-grow {
  flex-grow: 1;
}

.translate-x-1\/3 {
  --tw-translate-x: 33.333333%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/4 {
  --tw-translate-y: -25%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-3 {
  --tw-rotate: 3deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes bounce {
  0%,
  to {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce {
  0%,
  to {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce {
  -webkit-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
}

@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.list-disc {
  list-style-type: disc;
}

.columns-2 {
  -moz-columns: 2;
  columns: 2;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(37 50 56 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-primary\/10 {
  background-color: #2532381a;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(37 50 56 / var(--tw-bg-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.\!bg-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 50 56 / var(--tw-bg-opacity)) !important;
}

.\!bg-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 84 92 / var(--tw-bg-opacity)) !important;
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.\!bg-brand-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
}

.\!bg-brand-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
}

.\!bg-emerald-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}

.\!bg-transparent {
  background-color: transparent !important;
}

.\!bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.p-2 {
  padding: 0.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-1 {
  padding: 0.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.text-center {
  text-align: center;
}

.font-sans {
  font-family: $font_title;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-black {
  font-weight: 900;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -0.025em;
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-primary\/5 {
  color: #2532380d;
}

.text-primary\/10 {
  color: #2532381a;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(37 50 56 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-brand-primary {
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(66 84 92 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.no-underline {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

.placeholder-gray-500::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.placeholder-gray-500:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.placeholder-slate-400::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

.placeholder-slate-400:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

.placeholder-slate-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity));
}

.opacity-50 {
  opacity: 0.5;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-gray-900\/10 {
  --tw-ring-color: rgb(17 24 39 / 0.1);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
    var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
    var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
    var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.duration-700 {
  transition-duration: 0.7s;
}

@-webkit-keyframes enter {
  0% {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0)
      scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes enter {
  0% {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0)
      scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@-webkit-keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0)
      scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0)
      scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.animate-in {
  -webkit-animation-name: enter;
  animation-name: enter;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
}

.fade-in {
  --tw-enter-opacity: 0;
}

.duration-700 {
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}

.repeat-\[4\] {
  -webkit-animation-iteration-count: 4;
  animation-iteration-count: 4;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
    skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.hover\:\!bg-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 84 92 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 50 56 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:\!bg-brand-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-brand-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.hover\:\!bg-slate-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:text-brand-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:ring-slate-700:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(37 50 56 / var(--tw-border-opacity));
}

.focus\:border-brand-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 110 / var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 50 56 / var(--tw-ring-opacity));
}

.focus\:ring-primary\/75:focus {
  --tw-ring-color: rgb(37 50 56 / 0.75);
}

.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}

.focus\:ring-slate-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
}

.focus\:ring-brand-primary\/75:focus {
  --tw-ring-color: rgb(255 0 110 / 0.75);
}

.focus\:ring-brand-secondary\/75:focus {
  --tw-ring-color: rgb(255 190 11 / 0.75);
}

.focus\:ring-emerald-500\/75:focus {
  --tw-ring-color: rgb(16 185 129 / 0.75);
}

.focus\:ring-slate-300\/75:focus {
  --tw-ring-color: rgb(203 213 225 / 0.75);
}

.focus\:ring-brand-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(255 0 110 / var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus-visible\:ring-primary:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 50 56 / var(--tw-ring-opacity));
}

.active\:shadow-none:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:\!bg-slate-300:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}

.disabled\:text-slate-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:-mt-32 {
    margin-top: -8rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:-mr-48 {
    margin-right: -12rem;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:flex-star {
    justify-content: flex-start;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }

  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-divide-opacity));
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pr-3 {
    padding-right: 0.75rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-3 {
    padding-left: 0.75rem;
  }

  .sm\:pl-2\.5 {
    padding-left: 0.625rem;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 768px) {
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:-ml-16 {
    margin-left: -4rem;
  }

  .md\:ml-3 {
    margin-left: 0.75rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-star {
    justify-content: flex-start;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:inset-y-0 {
    top: 0px;
    bottom: 0px;
  }

  .lg\:left-0 {
    left: 0px;
  }

  .lg\:right-0 {
    right: 0px;
  }

  .lg\:left-80 {
    left: 20rem;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:gap-24 {
    gap: 6rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-48 {
    padding-top: 2rem;
    padding-bottom: 12rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }
}

@media (min-width: 1536px) {
  .\32xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .\32xl\:h-full {
    height: 100%;
  }

  .\32xl\:max-h-\[44rem\] {
    max-height: 44rem;
  }

  .\32xl\:max-w-2xl {
    max-width: 42rem;
  }

  .\32xl\:max-w-3xl {
    max-width: 48rem;
  }

  .\32xl\:max-w-none {
    max-width: none;
  }

  .\32xl\:gap-24 {
    gap: 6rem;
  }

  .\32xl\:justify-self-end {
    justify-self: end;
  }

  .\32xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .\32xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .\32xl\:pl-20 {
    padding-left: 5rem;
  }
}

html,
body,
#app {
  margin: 0;
  height: 100vh;
  padding: 0;
}

.wrap[data-v-29401157] {
  position: fixed;
  right: 2.5rem;
  bottom: 2.5rem;
}

.mixo-badge[data-v-29401157] {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.mixo-badge[data-v-29401157]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}

.mixo-badge[data-v-29401157] {
  z-index: 999;
}

ile-root:empty {
  display: none;
}

.ui-loader-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.ui-loader-base .ui-loader-icon {
  margin-right: 0.375rem;
  height: 1.25rem;
  width: 1.25rem;
}

.ui-loader-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ui-loader-sm .ui-loader-icon {
  margin-right: 0.25rem;
  height: 1rem;
  width: 1rem;
}

.ui-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ui-button > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.ui-button {
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  text-align: center;
  font-weight: 500;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;

  &.bold {
    font-family: $font_title;
    font-weight: bold;
  }
}

.ui-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ui-button-xs {
  padding: 0.375rem 0.625rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.ui-button-sm {
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ui-button-base,
.ui-button-md {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.ui-button-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.ui-button-xl {
  padding: 1rem 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ui-button-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 50 56 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 84 92 / var(--tw-bg-opacity)) !important;
}

.ui-button-primary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(37 50 56 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-primary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 84 92 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 50 56 / var(--tw-bg-opacity)) !important;
}

.ui-button-secondary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 50 56 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
}

.ui-button-secondary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-secondary .ui-button-danger:hover {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.ui-button-secondary .ui-button-danger:focus {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity));
}

.ui-button-tertiary {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-tertiary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.ui-button-tertiary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
}

.ui-button-link {
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgb(37 50 56 / var(--tw-text-opacity));
}

.ui-button-link:hover {
  --tw-text-opacity: 1;
  color: rgb(66 84 92 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.ui-button-link .ui-button-danger:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.ui-button-brand-primary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-primary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.ui-button-brand-primary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 0 110 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-brand-primary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-primary:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.ui-button-brand-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 190 11 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-secondary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ui-button-brand-secondary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 190 11 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-brand-secondary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-success {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-success:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 0 110 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.ui-button-brand-success:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(16 185 129 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-brand-success:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-brand-link {
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}

.ui-button-brand-link:hover {
  --tw-text-opacity: 1;
  color: rgb(255 190 11 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.ui-button-brand-link .ui-button-danger:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.ui-button-outline {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  background-color: transparent !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-outline:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.ui-button-outline:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(255 190 11 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-outline:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-tertiary {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-button-tertiary:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.ui-button-tertiary:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: rgb(203 213 225 / 0.75);
  --tw-ring-offset-width: 2px;
}

.ui-button-tertiary:active {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ui-alert-danger[data-v-54706fd2],
.ui-alert-error[data-v-54706fd2] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(254 226 226 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity));
}

.ui-alert-danger .ui-alert-icon[data-v-54706fd2],
.ui-alert-error .ui-alert-icon[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.ui-alert-danger .ui-alert-title[data-v-54706fd2],
.ui-alert-error .ui-alert-title[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}

.ui-alert-danger .ui-alert-content[data-v-54706fd2],
.ui-alert-error .ui-alert-content[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.ui-alert-danger .ui-alert-details[data-v-54706fd2],
.ui-alert-error .ui-alert-details[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.ui-alert-info[data-v-54706fd2] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.ui-alert-info .ui-alert-icon[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.ui-alert-info .ui-alert-title[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.ui-alert-info .ui-alert-content[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.ui-alert-info .ui-alert-details[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.ui-alert-success[data-v-54706fd2] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.ui-alert-success .ui-alert-icon[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.ui-alert-success .ui-alert-title[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.ui-alert-success .ui-alert-content[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.ui-alert-success .ui-alert-details[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.ui-alert-warning[data-v-54706fd2] {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(254 249 195 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.ui-alert-warning .ui-alert-icon[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.ui-alert-warning .ui-alert-title[data-v-54706fd2],
.ui-alert-warning .ui-alert-content[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.ui-alert-warning .ui-alert-details[data-v-54706fd2] {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.footer-link {
  --tw-text-opacity: 1;
  color: rgb(255 0 110 / var(--tw-text-opacity));
}

.footer-link:hover {
  --tw-text-opacity: 1;
  color: rgb(255 190 11 / var(--tw-text-opacity));
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.user-avatar[data-v-77b1cd82] {
  margin: 0.5rem 0.25rem;
  display: block;
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(37 50 56 / var(--tw-border-opacity));
}

@media (min-width: 640px) {
  .user-avatar[data-v-77b1cd82] {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    height: 3.5rem;
    width: 3.5rem;
  }
}

.note-text[data-v-1ff96999] {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 1024px) {
  .note-text[data-v-1ff96999] {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@-webkit-keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@-webkit-keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}

.plyr audio,
.plyr iframe,
.plyr video {
  display: block;
  height: 100%;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *,
.plyr--full-ui :after,
.plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: #fff;
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  -webkit-animation: plyr-fade-in 0.3s ease;
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: 20px;
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: #000c;
  background: var(--plyr-captions-background, #000c);
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: #0000;
  border: 0;
  border-radius: 3px;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
  position: relative;
  transition: all 0.3s ease;
}

.plyr__control svg {
  fill: currentColor;
  display: block;
  height: 18px;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: 18px;
  width: var(--plyr-control-icon-size, 18px);
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control:focus-visible {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control.plyr__control--pressed .label--not-pressed,
.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
a.plyr__control:after,
a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,
.plyr__controls .plyr__controls__item.plyr__time + .plyr__time,
.plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr="airplay"],
.plyr [data-plyr="captions"],
.plyr [data-plyr="fullscreen"],
.plyr [data-plyr="pip"],
.plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"],
.plyr--captions-enabled [data-plyr="captions"],
.plyr--fullscreen-enabled [data-plyr="fullscreen"],
.plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  -webkit-animation: plyr-popup 0.2s ease;
  animation: plyr-popup 0.2s ease;
  background: #ffffffe6;
  background: var(--plyr-menu-background, #ffffffe6);
  border-radius: 4px;
  border-radius: var(--plyr-menu-radius, 4px);
  bottom: 100%;
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}

.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}

.plyr__menu__container:after {
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  border: var(--plyr-menu-arrow-size, 4px) solid #0000;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  height: 0;
  position: absolute;
  right: 14px;
  right: calc(
    var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * 0.7 - var(--plyr-menu-arrow-size, 4px) / 2
  );
  top: 100%;
  width: 0;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
}

.plyr__menu__container [role="menuitem"],
.plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child,
.plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  display: flex;
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: 4.66667px 10.5px;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7 / 1.5) calc(var(--plyr-control-spacing, 10px) * 0.7 * 1.5);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid #0000;
  border: var(--plyr-menu-item-arrow-size, 4px) solid #0000;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: calc(var(--plyr-control-spacing, 10px) * 0.7 * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: 6.5px;
  right: calc(var(--plyr-control-spacing, 10px) * 0.7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward:focus-visible:after,
.plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: initial;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 7px;
  margin: calc(var(--plyr-control-spacing, 10px) * 0.7);
  margin-bottom: 3.5px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) * 0.7 / 2);
  padding-left: 28px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7 * 4);
  position: relative;
  width: calc(100% - 14px);
  width: calc(100% - var(--plyr-control-spacing, 10px) * 0.7 * 2);
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: 6.5px;
  left: calc(var(--plyr-control-spacing, 10px) * 0.7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: 3.5px;
  margin-top: calc(var(--plyr-control-spacing, 10px) * 0.7 / 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}

.plyr__menu__container .plyr__control--back:focus-visible:after,
.plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: initial;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after,
.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  background: #0000001a;
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #00b2ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:focus-visible:before,
.plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: -5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) * 0.7 * -1 - -2px);
  overflow: hidden;
  padding-left: 24.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7 * 3.5);
  pointer-events: none;
}

.plyr--full-ui input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #0000;
  border: 0;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: #00b2ff;
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  display: block;
  height: 19px;
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: #0000;
  background-image: linear-gradient(90deg, currentColor 0, #0000 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), #0000 var(--value, 0));
  border: 0;
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: -4px;
  margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2 * -1);
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  background: #0000;
  border: 0;
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -moz-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  background: currentColor;
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
}

.plyr--full-ui input[type="range"]::-ms-track {
  color: #0000;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper,
.plyr--full-ui input[type="range"]::-ms-track {
  background: #0000;
  border: 0;
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  background: #0000;
  background: currentColor;
  border: 0;
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
  position: relative;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]:focus-visible::-webkit-slider-runnable-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-moz-range-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-ms-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "\2044";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: #ffffffe6;
  background: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  bottom: 100%;
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: #4a5464;
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 5px 7.5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(var(--plyr-control-spacing, 10px) / 2 * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  white-space: nowrap;
  z-index: 2;
}

.plyr__tooltip:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control:focus-visible .plyr__tooltip,
.plyr .plyr__control:hover .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 0 100%;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * 0.7);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translateY(10px) scale(0.8);
  transform-origin: 100% 100%;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * 0.7);
  transform: translate(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0) scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"],
.plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  width: calc(100% + 13px);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}

.plyr__progress input[type="range"] {
  position: relative;
  z-index: 2;
}

.plyr__progress .plyr__tooltip {
  left: 0;
  max-width: 120px;
  overflow-wrap: break-word;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  background: #0000;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: -2.5px;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: #0000;
}

.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -moz-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  -webkit-animation: plyr-progress 1s linear infinite;
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0, #0000 50%, #23282f99 0, #23282f99 75%, #0000 0, #0000);
  background-image: linear-gradient(
    -45deg,
    var(--plyr-progress-loading-background, #23282f99) 25%,
    #0000 25%,
    #0000 50%,
    var(--plyr-progress-loading-background, #23282f99) 50%,
    var(--plyr-progress-loading-background, #23282f99) 75%,
    #0000 75%,
    #0000
  );
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: var(--plyr-progress-marker-background, #fff);
  border-radius: 1px;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3px;
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
}

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: 5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: 5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  max-width: 90px;
  min-width: 60px;
  position: relative;
  z-index: 2;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control:focus-visible,
.plyr--audio .plyr__control:hover,
.plyr--audio .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: #fff;
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: #c1c8d199;
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16/9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed,
  .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: 5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: 20px;
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: 35px;
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control:focus-visible,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}

.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}

.plyr__control--overlaid:focus,
.plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33),
    0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: #ffffff40;
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000000;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}

.plyr__ads:after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: #ffffffe6;
  background-color: var(--plyr-tooltip-background, #ffffffe6);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  bottom: 100%;
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translateY(10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s ease 0.1s, opacity 0.2s ease 0.1s;
  z-index: 2;
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0) scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #ffffffe6;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #ffffffe6);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: calc(50% + var(--preview-arrow-offset));
  position: absolute;
  transform: translate(-50%);
  width: 0;
  z-index: 2;
}

.plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: 4px;
  border-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.plyr__preview-thumb__image-container img,
.plyr__preview-thumb__image-container:after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px #00000026;
  content: "";
  pointer-events: none;
}

.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: 4px;
  border-bottom-left-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  border-bottom-right-radius: 4px;
  border-bottom-right-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  bottom: 0;
  left: 0;
  line-height: 1.1;
  padding: 20px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  -o-object-fit: contain;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0 !important;
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

// Login
.login-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Admin
.admin-container {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .prod-row {
    display: flex;
    flex-direction: row;

    .prod-count {
      display: "block";
      width: 50px;
    }
  }
}

.message-btn-container {
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    max-width: 300px;
  }
}

.note-text {
  white-space: pre-line;
}
