// Header
$black: #111827;
$blue: #3d80f7;
$gray: #6b7280;
$light_gray: #e9eaeb;
$light_black: #474747;
$white: #fff;

// Fonts
$font_title: Poppins;
$font_body: Poppins;

// Sizes
$mobile_size: 425px;
$tablet_size: 768px;
$desktop_size: 1024px;

// Poppins
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=IM+Fell+DW+Pica:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Mallanna:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap");

@import "./newStyle.scss";

html,
body {
  margin: 0;
  padding: 0;
  // font-family: $font_body;
  color: $black;
}

h1,
h2,
h3 {
  font-family: $font_title;
}

a,
a:visited {
  color: $blue;

  &.button {
    color: $white;
  }
}

.wmde-markdown {
  ul {
    line-height: normal;
    list-style: inside;
    li {
      line-height: normal;
    }
  }
}

a:visited.button.link {
  color: $blue;
}

.header {
  background-color: $white;
  color: $black;
  font-family: $font_title;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 35px;
  line-height: 20px;

  .header-logo-text {
    font-family: $font_title;
    margin-left: 10px;
    font-weight: 600;
    color: $black;
    font-size: 20px;
    line-height: 28px;
    tab-size: 4;
    white-space: nowrap;
    line-height: 80px;
  }

  .header-logo {
    height: 35px;
  }

  a,
  a:visited {
    color: $white;
    text-decoration: none;
  }

  .header-left {
    display: flex;
    flex-direction: row;

    a {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .header-btn {
      cursor: pointer;
      margin-left: 10px;
    }
  }

  .header-right {
    text-align: end;
    display: flex;
    line-height: 30px;
    flex-wrap: wrap;

    @media (max-width: $mobile_size) {
      display: block;
      text-align: left;
    }

    .header-btn {
      margin-right: 20px;
      white-space: nowrap;

      a {
        color: $black;
        font-weight: 500;
      }

      &.main {
        a {
          color: $blue;
          font-weight: 600;
        }
      }
    }
  }
}

.footer {
  background-color: $blue;
  color: $white;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  font-family: "Gloria Hallelujah";
  line-height: 20px;
  margin-bottom: 0px;

  a,
  a:visited {
    color: $white;
    text-decoration: none;
  }

  .footer-left {
    display: flex;
    flex-direction: row;
  }

  .footer-center {
    font-weight: bold;
    text-transform: capitalize;

    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    a {
      display: flex;
    }

    svg,
    img {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }

  .footer-right {
    text-align: end;
  }
}

// Components

input {
  height: 30px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid $gray;
  outline: none;
  padding: 5px 10px;
  margin-bottom: 5px;
  display: block;
}

button,
.button {
  font-family: $font_body;
  border-radius: 25px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 8px 8px;
  background-color: $blue;
  color: $white;
  padding: 10px 20px;
  height: auto;
  width: fit-content;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
  cursor: pointer;

  &.small {
    padding: 10px 20px;
    font-size: 15px;
    line-height: 15px;
    margin-right: 20px;
    font-weight: 400;
  }

  &.black {
    background-color: $black;
  }

  &.link {
    background-color: transparent;
    box-shadow: none;
    color: $gray;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    width: fit-content;
    max-width: fit-content;

    &:hover {
      text-decoration: underline;
    }
  }
}

a:visited {
  color: $blue;
}

// Layout

.main-container {
}

// HOME
.home-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
  }

  .subtitle {
    color: $gray;
    margin-top: 30px;
    font-size: 19px;
  }

  .meta {
    font-family: $font_body;
    font-size: 16px;
    margin-bottom: 30px;
    max-width: 400px;
    text-align: center;
  }

  .textarea-container {
    width: 100%;
    text-align: center;
    max-width: 400px;

    .textarea {
      min-width: 95%;
      height: auto;
      border-radius: 10px;
      border: 1px solid $gray;
      box-shadow: rgba(0, 0, 0, 0.18) 0px 8px 8px;
      outline: none;
      cursor: pointer;
      resize: none;
      margin-bottom: 30px;
      padding: 10px;
      font-family: $font_body;
      line-height: 20px;
      font-size: 17px;
    }
  }

  .promote {
    margin-top: 30px;
    color: $gray;
  }
}

.how-it-works-content {
  max-width: 500px;
  margin: 0 auto;

  .number-home {
    margin: 0 auto;
    font-size: 25px;
    font-weight: bold;
    color: $black;
    margin-top: 40px;
    margin-bottom: 5px;
    border-radius: 100%;
    border: 5px solid $black;
    width: 40px;
    height: 40px;
  }
}

// Modal

.share-modal-backdrop {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.18);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;

  .share-modal {
    z-index: 9999;
    max-width: 80vw;
    position: absolute;
    background-color: $white;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 8px 8px;
    border: 1px solid rgba(0, 0, 0, 0.18);
    padding: 20px;
    top: 20%;
    min-height: 40%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;

    .modal-content {
      max-width: 100%;
    }

    .share-btn-text {
      font-size: 23px;
      line-height: 29px;
      padding: 20px;
      line-height: 35px;
      padding-bottom: 30px;
    }

    .share-btn-container {
      display: inline-block;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      padding-bottom: 30px;

      button {
        min-width: 0;
        margin: 10px;
        width: 64px;
        height: 64px;
      }
    }

    .close-btn {
      min-width: 0;
      padding: 0;
      position: absolute;
      right: -15px;
      top: -15px;
      background-color: $black;
      color: $white;
      border-radius: 20px;
      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: inline-block;
      text-align: center;
    }
  }
}

// Submit
.submit-container {
  padding: 20px;

  .submit-text {
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
  }

  div {
    font-family: $font_body;
  }

  .plans-container {
    text-align: center;
    padding-bottom: 30px;

    .plan {
      text-align: center;
    }

    .plan-title {
      font-size: 25px;
      font-weight: bold;
      margin-top: 30px;
      font-family: "Gloria Hallelujah";
    }
    .plan-price {
      font-size: 25px;
      font-weight: bolder;
    }

    .raccomanded-text {
      color: $gray;
    }
  }
}

.submit-confirm-container {
  text-align: center;
  padding-top: 50px;
}

// Login
.login-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Admin
.admin-container {
  padding: 20px;
  display: flex;
  flex-direction: column;

  .table-container {
    width: 100%;
    overflow: scroll;
  }

  table {
    margin-top: 30px;

    thead {
      background-color: $blue;
      color: $white;
      td {
        padding: 2px 15px;
      }
    }

    tr {
      td {
        max-width: 400px;
        line-break: anywhere;
        vertical-align: top;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:nth-child(even) {
        background-color: $light_gray;
      }
    }
  }

  thead {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .product-image {
    width: 100px;
  }

  .product-views {
    width: 100px;
    text-align: center;
  }

  .product-name {
    min-width: 200px;
  }

  .product-url a {
    white-space: nowrap;
  }

  .tags-container {
    display: flex;
    flex-wrap: nowrap;

    .tag {
      display: inline-block;
      margin-right: 15px;
      padding: 5px 20px;
      width: fit-content;
      border-radius: 25px;
      border-color: none;
      background-color: $light_gray;
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  .btn-bar {
    margin-top: 20px;
    margin-bottom: 15px;

    & > div {
      display: inline-block;
    }
  }

  .share-modal {
    width: 90%;
    text-align: left;
    align-items: start;
    top: 20px;
    height: 90%;
    display: block;

    .modal-content {
      overscroll-behavior: contain;

      overflow: scroll;
      display: block;
      width: 100%;
      height: 100%;

      .btn-bar {
        margin-top: 20px;
        margin-bottom: 30px;

        & > div {
          display: inline-block;
        }
      }

      .label-row {
        width: 100%;
        label {
          display: inline-block;
          width: 150px;
        }

        & > input,
        & > div {
          display: inline-block;
        }

        input {
          width: calc(100% - 150px);
        }

        input[type="checkbox"] {
          width: 40px;
          height: 40px;
        }

        // textarea {
        //   width: 100%;
        //   height: 350px;
        //   border: 1px solid $gray;
        //   border-radius: 10px;
        //   margin-top: 15px;
        //   padding: 10px;
        // }

        .container-markdown {
          width: 98%;
          margin-left: 1%;
          margin-top: 20px;
          height: 410px;
        }
      }
    }
  }
}

.navbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999;

  &.medium-show {
    padding: 20px;
  }

  a {
    font-weight: bold;
  }

  .ui-button {
    width: auto;
    min-width: auto;
    height: 30px;
    margin-left: 20px;
  }
}

@media (min-width: 1024px) {
  .navbar.small-show {
    display: none;
  }
}

@media (max-width: 1024px) {
  .navbar.medium-show {
    display: none;
  }
}

@media (max-width: 640px) {
  .navbar.small-show {
    .ui-button {
      display: none;
    }
  }
}

// Products page
.products-list-page {
  padding: 20px 180px;

  @media (max-width: $desktop_size) {
    padding: 20px 40px;
  }
}

.product-container {
  display: flex;
  flex-direction: row;
  padding: 40px;

  @media (max-width: $tablet_size) {
    flex-direction: column;
    padding: 0px;
    padding-top: 20px;
  }

  .product-image {
    width: 150px;
    height: fit-content;

    @media (max-width: $tablet_size) {
      width: 100%;
      margin-top: 30px;
    }
  }

  .product-body {
    padding: 10px 40px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet_size) {
      padding: 0px;
      padding-top: 20px;
    }

    .product-name {
      font-weight: bold;
      font-size: 20px;
    }

    .product-description {
      margin-bottom: 0px;
    }
  }

  .tags-container {
    margin-top: 30px;
    margin-bottom: 10px;

    .tag {
      display: inline-block;
      margin-right: 15px;
      padding: 5px 20px;
      width: fit-content;
      border-radius: 25px;
      border-color: none;
      background-color: $light_gray;
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

// Products detail page
.products-detail-page {
  padding: 20px 180px;

  .post-footer {
    margin-top: 30px;
  }

  .post-footer-btn {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  @media (max-width: $desktop_size) {
    padding: 20px 40px;
  }

  .product-container {
    flex-direction: column;

    .product-image-conatiner {
      width: 100%;

      .product-image {
        width: 100%;
        height: auto;
      }
    }

    .product-long-description {
      margin-top: 40px;
      margin-bottom: 20px;

      .wmde-markdown * {
        font-family: $font_body;
      }
    }

    .tags-container {
      margin-top: 5px;
      margin-bottom: 30px;
    }
  }
}

// Modal stats
.modal-stats {
  overflow-x: auto;
  table {
    white-space: nowrap;

    thead {
      background-color: $gray;
      color: $white;
    }

    td {
      padding: 3px 5px;
    }

    tr:nth-child(even) {
      background: $light_gray;
    }
  }
}

// Alert
.alert-success {
  background-color: #23884fc8;
  border: 1px solid #144e2d;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.alert-error {
  background-color: #a53939;
  border: 1px solid #561111;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
  line-break: anywhere;
}
